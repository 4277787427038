import * as React from "react"

import Layout from "../components/layout/Layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h1>La URL solicitada no se encontró</h1>

  </Layout>
)

export default NotFoundPage
